<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-8 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Configure Approvers</h1>
        </div>
        <div class="col-md-12 ml-auto mr-auto">
          <v-form ref="salarycomponent" v-model="valid">
          <v-row>
          <v-col>
             <SelectField
                :items="modules"
                v-model="selectedModule"
                :label="'Modules'"
                :itemText="'name'"
                :itemValue="'id'"
                :returnObject="true"
                @onChange="onChangeModule(selectedModule)"

              />
          </v-col>
          <v-col v-if="selectedModule && selectedModule.id === 1 ">
              <SelectField
                :items="allowanceType"
                v-model="selectedAllowance"
                :label="'Allowance Type'"
                :itemText="'description'"
                :itemValue="'id'"
                :returnObject="true"
                @onChange="onChangeAllowance(selectedModule, selectedAllowance)"
              />
          </v-col>
          <v-col>
            <TextField
                v-model="approverLevels"
                :type="'number'"
                :label="'Level'"
                :min="1"
                :validate="false"
                :max="3"
              />
          </v-col>
          <v-col>
         <v-simple-table>
            <thead>
              <tr>
                <th class="text-left" style="text-align:center">
                  S/N
                </th>
                <th class="text-left" style="text-align:center">
                  Role
                </th>
                <th class="text-left" style="text-align:center">
                  Selected Role(s)
                </th>
                <th class="text-left" style="text-align:center">
                  Level
                </th>
              </tr>
            </thead>
            <tbody>
        <tr v-for="item in approversArray" :key="item.id" >
          <td>{{ item.id+1 }}</td>

          <td>
            <div class="pt-6">
              <v-select
                :items="roleType"
                v-model="item.Selected"
                :label="''"
                :item-text="'description'"
                :item-value="'name'"
                multiple
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                outlined
                @blur="removeItem(item.Selected)"

              />
            </div>
          </td>

          <td >
            <span v-if="item.Selected.length > 0"> {{ selectedItems(item.Selected) }}</span>
            <span v-else-if="approvers.length === 0"> </span>
            <span v-else> {{ item.Approver }} </span>
          </td>

          <td>
            <input
             type="number"
             v-model="item.Level"
             readonly
            max="4"
            class="input"
            oninput="(!validity.rangeOverflow||(value=3)) ;"
            />
          </td>
        </tr>
      </tbody>
         </v-simple-table>
          </v-col>
          <v-col cols="12 mt-5" class="d-flex justify-end">
              <Button
                :label="'Reset'"
                :btnType="'Submit'"
                :color="'success'"
                @onClick="reload"
                class="mr-4"
              />
              <Button
                :label="'Submit'"
                :btnType="'Submit'"
                @onClick="dialog = true"
                :disabled="!valid"
                :color="'primary'"
                class="mr-4"
              />
          </v-col>
        </v-row>
          </v-form>
        </div>
      </div>
    </div>
    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSaving"  :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSubmit"/>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import TextField from '@/components/ui/form/TextField.vue'
import { salaryComponentService, roleService } from '@/services'
export default {
  components: {
    SelectField,
    ConfirmationDialog,
    TextField,
    Button
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      isSaving: false,
      dialog: false,
      valid: false,
      approvers: [],
      approversArray: [],
      getAllApprovers: [],
      selectedModule: null,
      selectedAllowance: '',
      approverLevels: 0,
      formData: {
        selectedApprover: '',
        level: 0
      },
      modules: [
        {
          id: 0, name: 'Timesheet'
        },
        {
          id: 1, name: 'Allowance'
        },
        {
          id: 2, name: 'PPE'
        },
        {
          id: 3, name: 'Training'
        },
        {
          id: 4, name: 'Benefits'
        },
        {
          id: 5, name: 'Medical Screening'
        },
        {
          id: 6, name: 'Leave'
        },
        {
          id: 7, name: 'Requisition'
        },
        {
          id: 8, name: 'Exit'
        }
      ],
      allowanceType: [],
      roleType: []
    }
  },
  watch: {
    approverLevels (newArray) {
      let arr = []
      if (this.approverLevels > 0 && this.approvers.length !== 0) {
        for (let i = 0; i < this.approverLevels; i++) {
          arr.push({
            id: i,
            Approver: this.approvers[i][`approver`],
            Selected: [],
            Level: this.approvers[i].level
          })
        }
      } else if (this.approverLevels > 0 && this.approvers.length === 0) {
        for (let i = 0; i < this.approverLevels; i++) {
          arr.push({
            id: i,
            Approver: [],
            Selected: [],
            Level: i + 1
          })
        }
      }
      this.approversArray = arr
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },

    onChangeAllowance (module, allowance) {
      if (allowance) {
        const salaryComponentId = this.selectedAllowance.id
        salaryComponentService.getModuleApprovers(module.id, salaryComponentId)
          .then(result => {
            this.getAllApprovers = result.data
            this.approverLevels = this.getAllApprovers.approverCount
            this.approvers = result.data.moduleApprovers
          })
          .catch(() => {})
      }
    },
    onChangeModule (module) {
      let salaryComponentId = 0
      if (module.id !== 1) {
        salaryComponentService.getModuleApprovers(module.id, salaryComponentId)
          .then(result => {
            this.getAllApprovers = result.data
            this.approverLevels = this.getAllApprovers.approverCount
            this.approvers = result.data.moduleApprovers
          })
          .catch(() => {
          })
      }
    },
    selectedItems (item) {
      return item.join('/')
    },
    checkDuplicate (array) {
      let val = false
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array.length; j++) {
          if (i !== j) {
            if (array[i].Approver === array[j].Approver) {
              val = true
              break
            }
          }
        }
      }
      return val
    },

    reload () { this.loadRoles() },

    removeItem (value) {
      for (let i = 0; i < this.roleType.length; i++) {
        for (let j = 0; j < value.length; j++) {
          if (this.roleType[i].name === value[j]) {
            this.roleType.splice(i, 1)
          }
        }
      }
      return this.roleType
    },

    onSubmit () {
      this.isSaving = true
      // console.log(JSON.stringify(this.approversArray))
      let Approvers = []
      let data = []
      let _action = 'Edit'
      let salaryId = 0
      if (this.selectedModule.id === 1) {
        salaryId = this.selectedAllowance.id
      }
      let ans = ''
      this.approversArray.forEach(per => {
        if (per.Selected.length > 0) {
          ans = this.selectedItems(per.Selected)
        } else {
          ans = per.Approver
        }
        return Approvers.push({
          Approver: ans,
          Level: per.Level
        })
      })

      if (this.checkDuplicate(Approvers)) {
        this.showAlertMessage('Same approver role selected for different level', 'error')
        this.isSaving = false
        this.dialog = false
      } else {
        if (this.approvers.length === 0) {
          _action = 'New'
        }
        data.push({
          Module: this.selectedModule.id,
          ModuleName: this.selectedModule.name,
          SalaryComponentId: salaryId,
          ApprovalCount: this.approverLevels,
          Approvers: Approvers,
          Action: _action
        })

        salaryComponentService.createApprovers(data[0]).then(() => { })
          .catch((error) => {
            console.log('error:', error)
            this.isSaving = false
            this.dialog = false
          }).finally(() => {
            this.isSaving = false
            this.dialog = false
            this.approverLevels = 0
            this.selectedModule = ''
            this.selectedAllowance = ''
          })
      }
    },

    loadRequestSalaryAllowance () {
      salaryComponentService
        .getRequestSalaryAllowance()
        .then(result => {
          this.allowanceType = result.data
        })
        .catch(() => {})
    },
    loadRoles () {
      roleService.getAllRoles().then((result) => {
        this.roleType = result.data.items
      }).catch(() => {})
    }

  },
  mounted () {
    this.loadRequestSalaryAllowance()
    this.loadRoles()
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.input {
  border-radius: 3px;
  border: 1px solid grey;
  height: 40px;
  padding-left: 5px;
  width: 90px;
}
</style>
